import ahoy from 'ahoy.js';
import 'swiped-events';
import './require-form-answer.js'

// Track changes to page
ahoy.trackClicks();
ahoy.trackSubmits();
ahoy.trackChanges();

// Track page views
document.addEventListener('turbolinks:load', () => {
  ahoy.trackView();
});

// Handle toggle button changes
document.addEventListener('turbolinks:load', () => {
  const elements = document.getElementsByClassName('toggle-ajax-btn');
  for (let element of elements) {
    element.addEventListener('ajax:success', event => {
      const active = event.detail[0];
      if (active) {
        element.classList.add('active');
      }
      else {
        element.classList.remove('active');
      }
    });
  }
});

// Swipe handling
const swipeListen = (direction, callback) => {
  document.addEventListener(`swiped-${direction}`, callback);
};

const redirectOnSwipe = (direction, url) => {
  swipeListen(direction, () => {
    window.location.href = url;
  });
};

const backOnSwipe = (direction) => {
  swipeListen(direction, () => {
    window.history.back();
  });
};

document.addEventListener('turbolinks:load', () => {
  const element = document.querySelector('[data-swipe-url]');
  if (element !== null) {
    ['left'].forEach((direction) => {
      redirectOnSwipe(direction, element.getAttribute('data-swipe-url'));
    });
    ['right'].forEach((direction) => {
      backOnSwipe(direction);
    });
  }
});


