// Disable next button if an answer is required
// works for checkboxes, radio-select,

// checkbox selected must be evaluated every time
// there is a click event on a checkbox
const checkboxSelected = function(checkBoxes) {
  for (let box of checkBoxes) {
    if (box.checked) {
      return true;
    }
  }
  return false;
}

// radio selected is checked once on page load
// for an existing answer.
const radioSelected = function(radios) {
  for (let radio of radios) {
    if (radio.classList.contains('active')) {
      return true;
    }
  }
  return false;
}

// pass the appropriate evaluation method of true/false to
// enable to disable the 'Next' button
const enableRequireButton = function(enabled=false) {
  const nextButton = document.getElementsByClassName('-btn-requires-answer')[0];

  if (enabled) {
    nextButton.disabled = false;
  } else {
    nextButton.disabled = true;
  }
}

const listenForRequiredAnswer = function(questionType) {

  if (questionType === 'radio') {

    const radios = document.getElementsByClassName('question-btn');
    enableRequireButton(radioSelected(radios));

    for (let radio of radios) {
        // for radios, as soon as one has been clicked,
        // selection is a known positive
        radio.addEventListener('click', event => {
          enableRequireButton(true);
      })
    }

  } else if (questionType === 'checkbox') {

    const checkBoxes = document.getElementsByClassName('form-check-input');
    enableRequireButton(checkboxSelected(checkBoxes));

    for (let box of checkBoxes) {
       box.addEventListener('click', event => {
        enableRequireButton(checkboxSelected(checkBoxes));
      })
    }
  }
}

const getQuestionType = function() {
  const section = document.getElementsByClassName('question-contents')[0];
  let qType = 'unknown';
  if (section && section.classList.contains('-single-choice')) {
    qType = 'radio';
  } else if (section && section.classList.contains('-yes-no')) {
    qType = 'radio';
  } else {
    qType = 'checkbox';
  }
  return qType
}

document.addEventListener('turbolinks:load', () => {
  const nextButton = document.getElementsByClassName('-btn-requires-answer')[0];
  if (nextButton) {
    const questionType = getQuestionType();
    listenForRequiredAnswer(questionType);
  }
});
